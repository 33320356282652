<template>
  <div class="coupon-bag-container">
    <div class="student-coupon-bag">
      <el-breadcrumb
        separator=">"
        class="genera-breadcrumb"
        style="display: flex; margin-left: 6px; margin-top: 21px"
      >
        <el-breadcrumb-item :to="{ path: '/liveMarketing/discountCoupon' }"
          >{{ lang.live_marketing }}</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/liveMarketing/discountCoupon' }"
          >{{ lang.live_promotion }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ lang.set_up_coupons }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div
        style="
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          margin: 21px 0 14px 5px;
        "
      >
          {{ lang.set_coupon_information }}
      </div>
      <div class="con">
        <div class="bag-input">
          <el-form :model="couponBagData" :rules="rules" ref="ruleForm" style="margin-left: 7px" width="100%">
            <el-form-item prop="coupon_name">
              <p class="bagTitle">
                  {{ lang.coupon_name }}
                <span class="tips">*</span>
              </p>
              <el-input v-model="couponBagData.coupon_name" class="el-input" :placeholder="lang.please_enter_the_coupon_name"></el-input>
            </el-form-item>
            <el-form-item prop="coupon_type">
              <p class="bagTitle">
                  {{ lang.coupon_type }}
                <span class="tips">*</span>
              </p>
              <el-select v-model="couponBagData.coupon_type" :placeholder="lang.please_select_a_coupon_type" @change="toggleClass(couponBagData.coupon_type)" :popper-append-to-body="false">
                <el-option v-for="item in typeSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="coupon_grant">
              <p class="bagTitle">
                  {{ lang.quantity_issued }}
                <span class="tips">*</span>
              </p>
              <div style="display: flex;flex-direction: row; align-items: center">
                  <el-input v-model="couponBagData.coupon_grant" class="el-input" :placeholder="`${lang.please_enter_the_quantity}(1-1000000)`" maxlength="7"></el-input>
                  <span class="word1">{{ lang.sheet }}</span>
              </div>
              <!-- <p
                style="
                  line-height: 17px;
                  margin-bottom: 0;
                  padding-left: 10px;
                  margin-top: 10px;
                  color: #939393;
                "
              >
                优惠券创建后，发放量只能增加不能减少
              </p> -->
            </el-form-item>
            <el-form-item prop="coupon_limit">
              <p class="bagTitle">
                  {{ lang.limit_per_person }}
                <span class="tips">*</span>
              </p>
                <div style="display: flex;flex-direction: row; align-items: center">
                    <el-input v-model="couponBagData.coupon_limit" class="el-input" :placeholder="`${lang.please_enter_the_quantity}(1-1000000)`" maxlength="7"></el-input>
                    <span class="word1">{{ lang.sheet }}</span>
                </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="bag-list">
          <div class="title">
            <div class="commodity-list">{{ lang.list_of_available_products }}({{ lang.selected }}{{ this.tableData.length }}{{ lang.several_items }})<span style="color: red; margin-left: 6px">*</span>
            </div>
            <div>
              <el-button class="button" @click="addcommodity">{{ lang.add_product }}</el-button>
            </div>
          </div>
          <div class="bag-tab">
            <el-table :data="tableData" style="width: 100%" :show-header="false" height="550px">
              <el-table-column prop="goods_name" :label="lang.commodity_information" width="180">
                <template slot-scope="scope">
                  <div class="goods-wrapper">
                    <div class="goods-cover" v-if="tableData != ''">
                      <img :src="scope.row.goods_master_img" alt="" />
                    </div>
                    <div class="text-overflow-2 goods-name" :title="scope.row.goods_name">
                      <span>{{ scope.row.goods_name }}</span><br /><span style="color: #9c9ca5">{{scope.row.goods_sku }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="name" :label="lang.reduction" width="400">
                <template slot-scope="scope">
                  <span style="box-sizing: border-box; padding-right: 5px">{{lang.full}}</span>
                  <el-input v-model="scope.row.coupon_full" class="el-input"></el-input>
                  <span v-show="show1" style="box-sizing: border-box;padding-right: 5px;padding-left: 5px;">{{ lang.reduce }}</span>
                  <span v-show="show2" style="box-sizing: border-box; padding-right: 5px">{{ lang.discount }}</span>
                  <el-input v-model="scope.row.coupon_minus" class="el-input"></el-input>
                  <span v-show="show1" style="box-sizing: border-box; padding-left: 5px">{{ lang.dollar }}</span>
                  <span v-show="show2" style="box-sizing: border-box; padding-right: 5px">{{lang.convert_into_money}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="address" :label="lang.operate" >
                <template slot-scope="scope">
                  <div class="link-list" style="text-align: center">
                    <el-link type="danger" :underline="false" @click="deleteCouponBag(scope.row.goods_id)">{{ lang.delete }}</el-link>
                  </div>
                </template>
              </el-table-column>
              <template slot="empty">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  ">
                  <img src="../../../../assets/image/studentCoupon/no_data_coupon.png" style="margin-bottom: 10px; width: 300px"/>
                  <span>{{ lang.there_is_currently_no_data_available }}</span>
                </div>
              </template>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div class="form-button">
      <el-button class="button" @click="cancel">{{ lang.cancellation }}</el-button>
      <el-button class="button" type="primary" @click="establish"
        >{{ lang.create }}</el-button
      >
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="30%"
      @close="close"
      @open="open"
    >
      <template slot="title">
        <div style="font-weight: 600">
          <span
            style="
              display: inline-block;
              width: 4px;
              height: 12px;
              margin-right: 10px;
              background-color: #2821fc;
            "
          ></span
          >{{ lang.select_product }}
        </div>
      </template>
      <div>{{ lang.promotional_product }}</div>
      <div class="listDate">
        <el-scrollbar class="exam-content">
          <el-table
            ref="multipleSelectionTable"
            :data="commodityList"
            tooltip-effect="dark"
            style="width: 100%"
            row-key=""
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="100"> </el-table-column>
            <el-table-column :label="lang.commodity_information">
              <template slot-scope="scope">
                <div class="goods-wrapper">
                  <div class="goods-cover">
                    <img :src="scope.row.goods_master_img" alt="" />
                  </div>
                  <div
                    class="text-overflow-2 goods-name"
                    :title="scope.row.goods_name"
                  >
                    <span>{{ scope.row.goods_name }}</span
                    ><br /><span style="color: #9c9ca5">{{
                      scope.row.goods_sku
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="lang.commodity_price"
            >
            <template slot-scope="scope">
              {{scope.row.goods_market_price}} {{ lang.dollar }}
            </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
       <el-pagination
          class="pages-center"
          :current-page="listPages.currentPageNum"
          :page-size="listPages.eachPageNum"
          layout="prev, pager, next, jumper"
          :total="listPages.total"
          @current-change="pageCurrentChange"
        >
        </el-pagination>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">{{ lang.cancellation }}</el-button>
        <el-button type="primary" @click="addcom">{{ lang.increase }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { live_script_goods, addCoupon, couponDetail } from "@/utils/apis";
import liveMarketingLang from '@/lang/liveMarketing'
export default {
  data() {
    return {
        lang: liveMarketingLang,
      couponBagData: {
        coupon_name: "", //名称
        coupon_type: 1, //类型
        coupon_grant: "", //发放量
        coupon_limit: "", //限领
      },
       listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      type: 1,
      toggleClassType:'',
      show1: true,
      show2: false,
      centerDialogVisible: false,
      coupon_student_id: "",
      typeSelectList: [
        {
          id: 1,
          name: liveMarketingLang.product_full_amount_reduction_coupon,
          value: 1,
        },
        {
          id: 2,
          name: liveMarketingLang.designated_product_discount_roll,
          value: 2,
        },
      ],
      commodityList: [],
      rules: {
        coupon_name: [
          { required: true, message: liveMarketingLang.please_enter_the_coupon_name, trigger: "blur" },
          // { min: 2, max: 15, message: "长度在2到15个字符", trigger: "blur" },
        ],
        coupon_grant: [
          { required: true, message: `${liveMarketingLang.please_enter_the_quantity}(1-1000000)`, trigger: "blur" },
          // { min: 2, max: 15, message: "长度在2到15个字符", trigger: "blur" },
        ],
        coupon_limit: [
          { required: true, message: `${liveMarketingLang.please_enter_the_quantity}(1-1000000)`, trigger: "blur" },
          // { min: 2, max: 15, message: "长度在2到15个字符", trigger: "blur" },
        ],
      },
      multipleSelection: [],
      tableData: [],
    };
  },
  computed: {},
  mounted() {
    this.getAddList();
    //判断是不是编辑
    if (this.$route.query.coupon_student_id) {
      this.compile();
    }
  },
  methods: {
    //取消
    cancel() {
      this.$router.push({
        path: "/liveMarketing/DiscountCoupon",
      });
    },
    //删除
    deleteCouponBag(goods_id) {

       var index=this.tableData.findIndex(item => {
          if(item.goods_id==goods_id) {
              return true;
          }
      })
                   
      this.tableData.splice(index, 1);
      // this.$message({
      //   type: "success",
      //   message: "删除成功",
      // });
    },
    //类型选择
    toggleClass(val) {
      if (val == 1) {
        this.toggleClassType = 1
        this.show1 = true;
        this.show2 = false;
      } else {
        this.toggleClassType = 2
        this.show1 = false;
        this.show2 = true;
      }
    },
    open() {
      this.$nextTick(() => {
        this.$refs.multipleSelectionTable.clearSelection();
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.commodityList.length; j++) {
            if (this.tableData[i].goods_id == this.commodityList[j].goods_id) {
              this.$refs.multipleSelectionTable.toggleRowSelection(
                this.commodityList[j],
                true
              );
            }
          }
        }
      });
    },
    close() {},
    addcom() {
        if(this.$route.query.coupon_student_id){
         
          for(let i=0;i<this.multipleSelection.length;i++){
             for(let j=0;j<this.tableData.length;j++){
               if(this.multipleSelection[i].goods_id ==this.tableData[j].goods_id){
                 this.multipleSelection[i].coupon_full = this.tableData[j].coupon_full
                 this.multipleSelection[i].coupon_minus = this.tableData[j].coupon_minus
               }
             }
          }
          this.centerDialogVisible = false;
          this.tableData = this.multipleSelection
        }else{
          this.centerDialogVisible = false;
          this.tableData = this.multipleSelection;
        }
    
    },
    addcommodity() {
      this.centerDialogVisible = true;
      if (this.$route.query.coupon_student_id) {
        this.$nextTick(() => {
          // this.$refs.multipleSelectionTable.clearSelection();
          for (let i = 0; i < this.tableData.length; i++) {
            for (let j = 0; j < this.commodityList.length; j++) {
              if (
                this.tableData[i].goods_id == this.commodityList[j].goods_id
              ) {
                this.$refs.multipleSelectionTable.toggleRowSelection(
                  this.commodityList[j],
                  true
                );
              }
            }
          }
        });
      }
    },
    //编辑
    compile() {
      let params = {
        coupon_student_id: this.$route.query.coupon_student_id,
      };  
      couponDetail(params)
        .then((res) => {
          if (res.code == 200) {
            console.log(res,'963.')
            this.couponBagData.coupon_name = res.data.coupon_name;
            this.couponBagData.coupon_type = res.data.coupon_type;
            this.couponBagData.coupon_grant = res.data.grant_count;
            this.couponBagData.coupon_limit = res.data.everyone_count;
            this.tableData = res.data.goods_data;
            this.toggleClassType = res.data.coupon_type
            console.log(res,'111')
          }

          if (this.couponBagData.coupon_type == 1) {
            this.show1 = true;
            this.show2 = false;
          } else {
            this.show1 = false;
            this.show2 = true;
          }

        })
        .catch((err) => {
          console.error("err", err);
        });
    }, 
     // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.getAddList();
    },
    //创建
    establish() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let tableList = [];
          let rule = /^(?=1\.[0-9]|[1-9]\.\d).{3}$|^([1-9])$/
          for (let i = 0; i < this.tableData.length; i++) {
            tableList.push({
              goods_id: this.tableData[i].goods_id.toString(),
              coupon_full: this.tableData[i].coupon_full.toString(),
              coupon_minus: this.tableData[i].coupon_minus.toString(),
            });
          if(this.toggleClassType ==2){
            if(!rule.test(tableList[i].coupon_minus)){
                 this.$message.success(`${liveMarketingLang.discount_range}1.0~9.9，${liveMarketingLang.one_decimal_place}`);
            return
            }
          }
          }

          if(parseInt(this.couponBagData.coupon_limit)>this.couponBagData.coupon_grant){
             this.$message.success(liveMarketingLang.per_person_limit);
          }else{
            let params = {
            coupon_name: this.couponBagData.coupon_name.toString(), //优惠券名称
            coupon_type: this.couponBagData.coupon_type.toString(), //优惠券类型
            grant_count: this.couponBagData.coupon_grant.toString(), //发放量
            everyone_count: this.couponBagData.coupon_limit.toString(), //限领
            goods_data: JSON.stringify(tableList), //商品数据
            coupon_student_id: this.$route.query.coupon_student_id, //编辑ID
          };
          addCoupon(params)
            .then((res) => {
              if (res.code == 200) {
                if (params.coupon_student_id) {
                  this.$message.success(liveMarketingLang.successfully_edited);
                } else {
                  this.$message.success(liveMarketingLang.created_successfully);
                }

                this.$router.push({
                  path: "/liveMarketing/DiscountCoupon",
                });
              }
            })
            .catch((err) => {
              console.error("err", err);
            });
          }

        
        }
      });
    },

    getAddList() {
      this.commodityList = [];
      let params = {
        script_id: JSON.parse(localStorage.getItem("scriptId")),
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      };
      live_script_goods(params)
        .then((res) => {
          if (res.code == 200) {
            
            this.listPages.total = res.data.total
            for (let i = 0; i < res.data.list.length; i++) {
              this.commodityList.push({
                goods_master_img:
                res.data.list[i].getGoodsInfo.goods_master_img,
                goods_name: res.data.list[i].getGoodsInfo.goods_name,
                goods_sku: res.data.list[i].getGoodsInfo.goods_sku,
                goods_id: res.data.list[i].getGoodsInfo.id,
                coupon_full: "",
                coupon_minus: "",
                goods_market_price:res.data.list[i].getGoodsInfo.goods_market_price
              });
            }
               console.log(this.commodityList,'987')
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    handleSelectionChange(val) {
      console.log(val,'123')
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang='scss' scoped>
.bag-tab {
  ::v-deep .el-table {
    color: #fff;
  }
}
.bag-tab {
  ::v-deep .el-table::before {
    height: 0px;
  }
}
.bag-tab {
  ::v-deep .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }
}

.bag-tab {
  ::v-deep .el-table tr {
    background-color: transparent !important;
  }
}
.bag-tab {
  ::v-deep .el-table tbody tr:hover > td {
    background-color: #070932 !important;
  }
}
.bag-tab{
  ::v-deep .el-table td {
  border-bottom: 1px solid #262963 !important;
}
} 
.bag-tab {
  ::v-deep .el-table--enable-row-transition .el-table__body td,
  .el-table .cell {
    background-color: transparent;
  }
}

.listDate {
  ::v-deep .el-table {
    color: #fff;
  }
}
.listDate {
  ::v-deep .el-table th.el-table__cell {
    background-color: #262963 !important;
  }
}
.listDate {
  ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px solid #ebeef5;
  }
}
.listDate {
  ::v-deep .el-table-column--selection .cell {
    padding-left: 10px;
    padding-right: 14px;
  }
}
.listDate {
  ::v-deep .el-table::before {
    height: 0px;
  }
}
.listDate {
  ::v-deep .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }
}

.listDate{
  ::v-deep .el-table tr {
  background-color: transparent !important;
}
} 
.listDate{
  ::v-deep .el-table tbody tr:hover > td {
  background-color: #262963 !important;
}
} 
.listDate{
  ::v-deep .el-table td {
  border-bottom: 1px solid #262963 !important;
}
} 
.listDate{
  ::v-deep .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}
} 

::v-deep.listDate .el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #262963;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #101341;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #101341;
  }
}
::v-deep.bag-tab .el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #101341;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #262963;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #262963;
  }
}

.coupon-bag-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .con {
    min-width: 780px;
  }

  .goods-wrapper {
    display: flex;
    align-items: center;
    .goods-cover {
      width: 50px;
      height: 50px;
      display: flex;
      background: #f6f6f6;
      border-radius: 8px;
      img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
    .goods-name {
      width: 1%;
      flex: 1;
      margin-left: 10px;
      font-size: 14px;
    }
  }
  .genera-breadcrumb {
    ::v-deep .el-breadcrumb__separator {
      margin: 0 9px;
      font-weight: 700;
      color: #5c5c6f;
    }
    ::v-deep .el-breadcrumb__item {
      &:last-child .el-breadcrumb__inner {
        color: #ababbb;
      }
      .el-breadcrumb__inner {
        color: #5c5c6f;
      }
    }
  }
  .listDate {
    height: 400px;
  }
  .bag-input {
    color: #fff;
    float: left;
    width: 40%;
    min-width: 311px;

    .tips {
      color: red;
      margin-left: 6px;
    }
    .bagTitle {
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 6px;
    }
    .el-input {
      width: 90%;
      height: 40px;
      // margin-top: 10px;
      margin: 10px 0 0 7px;
      border-radius: 4px;
    }
    .word1 {
      margin-left: 8px;
    }
  }
  ::v-deep .bag-list {
    width: 60%;
    min-width: 468px;
    display: inline;
    float: left;
    margin-top: 18px;
    padding: 0 10px;
    box-sizing: border-box;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .commodity-list {
        color: #fff;
      }
      .button {
        background: #4b45ff;
        color: #fff;
        border-color: #4b45ff;
        line-height: 0.5;
      }
    }
    .bag-tab {
      background-color: #0c0e3f;
      padding: 20px;
        min-width: 400px;
        overflow: hidden;
    }
  }
  ::v-deep .el-dialog {
    background: #070932;
    color: #fff;
  }

  ::v-deep .form-button {
    position: fixed;
    bottom: 1.85%;
    left: 68%;
    //width: 167px;
    height: 40px;
    .el-button {
      color: #fff;
      border: 1px solid #262963;
      background: #0c0e3f;
    }

    .el-button--primary {
      background: #4b45ff;
    }
    .el-button + .el-button {
      margin-left: 12px;
    }
  }
  ::v-deep .bag-list {
    .el-input {
      width: 38%;
    }
    .el-input__inner {
      color: #ffffff;
      background-color: #070932 !important;
      border: 1px solid transparent;
      &::placeholder {
        color: #ffffff;
        opacity: 0.2;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .el-input__inner:focus {
      border: 1px solid #4b45ff;
    }
  }

  ::v-deep .el-form {
    .el-form-item {
      margin-bottom: 0;
    }
    .el-input__inner {
      color: #ffffff;
      background-color: #0c0e3f !important;
      border: 1px solid transparent;
      &::placeholder {
        color: #ffffff;
        opacity: 0.2;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .el-input__inner:focus {
      border: 1px solid #4b45ff;
    }
    .el-form-item__error {
      margin-left: 7px;
    }
    .el-select {
      width: 90%;
      margin-top: 10px;
    }
    .el-icon-arrow-up:before {
      content: "\e790";
      color: #fff;
      opacity: 0.5;
      width: 12px;
      height: 7px;
    }
    .el-select-dropdown {
      height: auto;
      border: 1px solid transparent;
      background-color: #0c0e3f;
    }
    .el-scrollbar {
      height: auto;
    }
    .el-scrollbar__wrap {
      margin-bottom: 0 !important;
    }
    .el-select-dropdown__item {
      height: 36px;
      margin-top: 9px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
    .el-select-dropdown__item:hover {
      background-color: #262963;
    }
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #262963;
    }
    .el-select-dropdown__item.selected {
      background-color: #262963;
    }
  }
}
.exam-content {
  height: 100%;
  width: 100%;
  background: #070932;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;

    .el-scrollbar__view {
      height: 100%;
    }
  }
  }
::v-deep .pages-center{
  background: none;
  padding: 0px 0px !important;
  margin-top: 10px;
}
::v-deep .el-pagination button:disabled{
      background: none;
          color: #909399
}
::v-deep .el-pagination .btn-next{
   background: none;
      color: #fff
}
::v-deep .el-pagination .btn-prev{
    background: none;
          color: #fff
}
::v-deep .el-pager li{
    background: none;
    color: #fff !important;
}
</style>
